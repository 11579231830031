export enum DeviceNames {
    unknown = "unknown",
    iq = "Rhopoint IQ",
    glossmeter = "Rhopoint Glossmeter",
    detailometer = "Rhopoint Detailometer",
    duo = "Rhopoint Novo-Shade Duo",
    novoGloss = "Rhopoint Novo-Gloss",
    flex = "Rhopoint IQ Flex",

    thicknessGauge = "Coating Thickness Gauge"
}
