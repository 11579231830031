import { TranslateService } from "@ngx-translate/core";

import { StatisticsCalculator } from "../../../helpers/statistics/statistics-calculator";
import { UiHelper } from "../../../helpers/ui-helper";
import { PdfGenerator } from "../pdf-generator";
import { Row } from "./table/row";
import { Table } from "./table/table";

/**
 *
 */
export class StatisticsTable {
    constructor(private statistics: StatisticsCalculator, private translationService: TranslateService) {}

    public build(): Table {
        const statisticsTable: Table = new Table();
        statisticsTable.columns.push({
            title: `${this.translationService.instant("Statistics.type")}`,
            width: "*",
            fillColor: PdfGenerator.colorBlack,
            color: PdfGenerator.colorWhite
        });

        for (const valueName of this.statistics.getNames()) {
            let unit: string = UiHelper.formatUnit(this.statistics.getUnitForName(valueName));
            unit = (unit ? this.translationService.instant(unit) as string|undefined : "") ?? "";
            statisticsTable.columns.push({
                title: `${UiHelper.getTitleForColumn(valueName)}\n${(unit ? `/${unit}` : "")}`,
                width: "*",
                fillColor: PdfGenerator.colorBlack,
                color: PdfGenerator.colorWhite
            });
        }

        { // per measurement point
            for (const measurementPoint of this.statistics.getMeasurementPoints()) {
                const stdDev: Row = new Row();
                const min: Row = new Row();
                const max: Row = new Row();
                const avg: Row = new Row();
                stdDev.cells.push({
                    text: `#${measurementPoint} ${this.translationService.instant("Statistics.stdDev")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });
                min.cells.push({
                    text: `#${measurementPoint} ${this.translationService.instant("Statistics.min")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });
                max.cells.push({
                    text: `#${measurementPoint} ${this.translationService.instant("Statistics.max")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });
                avg.cells.push({
                    text: `#${measurementPoint} ${this.translationService.instant("Statistics.avg")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });

                for (const valueName of this.statistics.getNames()) {
                    const stdDevValue: string = this.statistics.stdDev(valueName, measurementPoint);
                    const minValue: string = this.statistics.min(valueName, measurementPoint);
                    const maxValue: string = this.statistics.max(valueName, measurementPoint);
                    const avgValue: string = this.statistics.avg(valueName, measurementPoint);
                    stdDev.cells.push({
                        text: stdDevValue
                    });
                    min.cells.push({
                        text: minValue
                    });
                    max.cells.push({
                        text: maxValue
                    });
                    avg.cells.push({
                        text: avgValue
                    });
                }
                statisticsTable.rows.push(stdDev);
                statisticsTable.rows.push(min);
                statisticsTable.rows.push(max);
                statisticsTable.rows.push(avg);
            }
            { // totals
                const stdDev: Row = new Row();
                const min: Row = new Row();
                const max: Row = new Row();
                const avg: Row = new Row();
                stdDev.cells.push({
                    text: `${this.translationService.instant("Statistics.stdDev")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });
                min.cells.push({
                    text: `${this.translationService.instant("Statistics.min")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });
                max.cells.push({
                    text: `${this.translationService.instant("Statistics.max")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });
                avg.cells.push({
                    text: `${this.translationService.instant("Statistics.avg")}`,
                    fillColor: PdfGenerator.colorLightGrey
                });
                for (const valueName of this.statistics.getNames()) {
                    const stdDevValue: string = this.statistics.stdDev(valueName);
                    const minValue: string = this.statistics.min(valueName);
                    const maxValue: string = this.statistics.max(valueName);
                    const avgValue: string = this.statistics.avg(valueName);
                    stdDev.cells.push({
                        text: stdDevValue
                    });
                    min.cells.push({
                        text: minValue
                    });
                    max.cells.push({
                        text: maxValue
                    });
                    avg.cells.push({
                        text: avgValue
                    });
                }
                statisticsTable.rows.push(stdDev);
                statisticsTable.rows.push(min);
                statisticsTable.rows.push(max);
                statisticsTable.rows.push(avg);
            }
        }
        return statisticsTable;
    }

    public static formatNumber(num: number|undefined): string {
        if (num) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return (Math.round(num * 100) / 100).toFixed(1);
        } else {
            return "0.0";
        }
    }
}
