import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { TranslateService } from "@ngx-translate/core";
import { register } from "swiper/element/bundle";

import { environment } from "../environments/environment";
import { EventHelper } from "./base/helper/event-helper";
import { AppUpdateService } from "./base/services/app-update/app-update.service";
import { BluetoothService } from "./base/services/ble/bluetooth.service";
import { TimeService } from "./base/services/time/time.service";
import { Timer } from "./base/services/time/timer";
import { UiHelper } from "./business/helpers/ui-helper";
import { AppService } from "./business/services/app/app.service";
import { LoggingService } from "./business/services/logging/logging.service";
import { MigrationService } from "./business/services/migration/migration.service";
import { Personalization } from "./business/services/settings/personalization";
import { SettingsService } from "./business/services/settings/settings-service";

register();

/**
 * AppComponent.
 */
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    constructor(
        private readonly appService: AppService,
        private readonly appUpdateService: AppUpdateService,
        private readonly bluetoothService: BluetoothService,
        private readonly timeService: TimeService,
        private readonly settingsService: SettingsService,
        private readonly migrationService: MigrationService,

        private readonly router: Router,
        private readonly zone: NgZone,

        loggingService: LoggingService,
        translateService: TranslateService
    ) {
        loggingService.intercept();
        console.info("Initializing app...");

        UiHelper.translateService = translateService;

        translateService.setDefaultLang("en");
        translateService.use("en");

        this.initializeDeepLinks();
    }

    private postInitTimer?: Timer;

    private initializeDeepLinks(): void {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const url: string = event.url as string;
                if (url.includes("id.rhopointservice.com")) {
                    const id: string|undefined = url.split("rhopointservice.com").pop();
                    if (id) {
                        this.router.navigateByUrl(`id${id}`).then();
                    }
                }
            });
        });
    }

    protected get showMenu(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return screen.width > 810;
    };

    public async ngOnInit(): Promise<void> {
        await this.initialize();
    }

    private async initialize(): Promise<void> {
        await this.appUpdateService.initialize();

        await this.appService.initialize();

        await this.migrationService.runMigration();

        await this.bluetoothService.initialize();

        const personalization: Personalization = await this.settingsService.loadPersonalization();
        UiHelper.lengthUnit = personalization.lengthUnit ?? "µm";

        this.appService.notifyAppCompletelyInitialized();

        this.postInitTimer = this.timeService.spawnTimer(environment.postAppInitDelay);
        EventHelper.subscribe(this.postInitTimer.elapsed, this.postInitApp, this);
        this.postInitTimer.start();
    }

    private postInitApp(): void {
        this.postInitTimer!.stop();

        this.appUpdateService.startUpdateCheck(environment.appUpdateCheckInterval, true);
        console.info("Post init done.");
    }
}
