import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AlertController, ModalController } from "@ionic/angular";
import type { ComponentRef } from "@ionic/core";
import { MessageService } from "primeng/api";

import { DeleteDialogComponent } from "../../components/delete-dialog/delete-dialog.component";
import { ErrorDialogComponent } from "../../components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../components/error-dialog/error-message";
import { RegisterFormComponent } from "../../components/register-form/register-form.component";
import { DeviceSubscriptionStatus } from "../subscription/device.subscription.status";

/**
 * Service to work with dialogs.
 */
@Injectable({
    providedIn: "root"
})
export class DialogService {
    constructor(
        private dialog: MatDialog,
        private messageService: MessageService,
        private readonly alertController: AlertController,
        private readonly modalController: ModalController
    ) {
    }

    public openDeleteDialog(title: string, description: string, confirmPassword?: string, confirmPasswordLabel?: string): Promise<boolean> {
        const dialog: MatDialogRef<DeleteDialogComponent> = this.dialog.open(DeleteDialogComponent, {
            data: {
                title: title,
                description: description,
                confirmPassword: confirmPassword,
                confirmPasswordLabel: confirmPasswordLabel
            }
        });

        return new Promise<boolean>((resolve: (value: (PromiseLike<boolean>|boolean)) => void) => {
            dialog.afterClosed().subscribe(async (result?: boolean) => {
                resolve(!!result);
            });
        });
    }

    public async confirm(title: string, description: string, okLabel: string, cancelLabel: string): Promise<boolean> {
        const alert: HTMLIonAlertElement = await this.alertController.create({
            header: title,
            message: description,
            buttons: [{text: okLabel, role: "confirm"}, {text: cancelLabel, role: "cancel"}]
        });

        alert.present().then();
        const result: { role: string|undefined } = await alert.onWillDismiss() as unknown as { role: string|undefined };

        return result.role == "confirm";
    }

    public licenseRequiredDialog(subscriptionStatus: DeviceSubscriptionStatus, serialNumber?: string): void {
        this.dialog.open(ErrorDialogComponent, {
            data: {
                title: "Subscription.missingSubscription",
                advice: [subscriptionStatus],
                details: [],
                severity: "warn",
                buttons: [{
                    title: "ErrorDialog.requestLicense",
                    component: RegisterFormComponent,
                    payload: serialNumber
                }]
            } as ErrorMessage
        });
    }

    public toast(severity: "success"|"info"|"warn"|"error", text: string, title?: string, sticky?: boolean): void {
        this.messageService.add({ severity: severity, summary: title, detail: text, sticky: sticky ?? false, contentStyleClass: "allow-line-feed" });
    }

    public async openDialog<TResult, TComponent extends ComponentRef = ComponentRef>(component: TComponent, settings: { fullscreen: boolean } = { fullscreen: false }, payload: { [key: string]: any }|undefined = undefined): Promise<TResult|undefined> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: component as ComponentRef,
            componentProps: payload,
            cssClass: settings.fullscreen ? "fullscreen-modal" : ""
        });
        modal.present().then();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { data } = await modal.onWillDismiss();

        return data ? data as TResult : undefined;
    }
}
