import { BleClient, BleDevice } from "@capacitor-community/bluetooth-le";

import { Measurement } from "../../../../business/datamodel/measurement";
import { BackendService } from "../../../../business/services/backend/backend-service";
import { SettingsService } from "../../../../business/services/settings/settings-service";
import { DeviceSubscriptionStatus } from "../../../../business/services/subscription/device.subscription.status";
import { BluetoothStatus } from "../bluetooth-status";
import { DeviceCodes } from "./device-codes";
import { DeviceNames } from "./device-names";

/**
 * Baseclass for all Bluetooth devices which are supported in this application.
 */
export abstract class BluetoothDevice {
    protected constructor(
        name: string,
        protected readonly backendService: BackendService,
        protected readonly settingsService: SettingsService
    ) {
        this.deviceName = name;
    }

    public deviceCode: string = DeviceCodes.unknown;
    public deviceName: string = DeviceNames.unknown;

    public serialNumber?: string;
    public localName?: string;

    protected bleDevice?: BleDevice;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    public signalStrength: number = 127; // 127 is the number for not-available
    private signalStrengthScanInterval?: any;
    private updatingSignalStrength: boolean = false;

    public abstract requiredServices: Array<string>;
    public abstract requiredAdvertisingServices: Array<string>;
    public abstract requiredNamePrefix?: string;

    public async connect(device: BleDevice, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus> {
        const self: BluetoothDevice = this;
        function handleDisconnect(): void {
            console.info(`Disconnected from device ${self.serialNumber}.`);
            self.stopSignalStrengthScan();
            onDisconnect();
        }

        const status: BluetoothStatus = await this.processConnect(device, onData, handleDisconnect);
        this.startSignalStrengthScan().then();
        return status;
    }

    public abstract processConnect(device: BleDevice, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus>;

    public abstract disconnect(): Promise<void>;

    public abstract isConnected(): Promise<boolean>;

    private async startSignalStrengthScan(): Promise<void> {
        await this.updateSignalStrength();

        const self: BluetoothDevice = this;
        this.signalStrengthScanInterval = setInterval(() => {
            self.updateSignalStrength().then();
        },
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        5000);
    }

    private stopSignalStrengthScan(): void {
        if (this.signalStrengthScanInterval) {
            clearInterval(this.signalStrengthScanInterval);
            this.signalStrengthScanInterval = undefined;
        }
    }

    private async updateSignalStrength(): Promise<void> {
        if (!this.bleDevice?.deviceId) {
            this.stopSignalStrengthScan();
        }
        try {
            this.signalStrength = await BleClient.readRssi(this.bleDevice!.deviceId);
            console.info(`Updated signal strength for device ${this.serialNumber}: ${this.signalStrength}`);
        } catch (error) {
            console.info("Unable to read signal strength.", error);
            this.stopSignalStrengthScan();
        }
    }

    protected async getSubscriptionStatus(): Promise<DeviceSubscriptionStatus> {
        if (!this.settingsService.licenseCheckEnabled) {
            return DeviceSubscriptionStatus.active;
        }
        return await this.backendService.hasValidLicense(this.serialNumber);
    }
}
