/* eslint-disable @typescript-eslint/no-magic-numbers */
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";

import { AppIcons } from "../../app-icons";
import { TypescriptHelper } from "../../base/helper/typescript-helper";
import { BluetoothStatus } from "../../base/services/ble/bluetooth-status";
import { MeasuredValue } from "../datamodel/measured-value";
import { Measurement } from "../datamodel/measurement";

/**
 * Helper methods for the UI.
 */
export class UiHelper {
    public static translateService: TranslateService|undefined = undefined;

    // This will be set on startup and setup change; it's being used to properly format the values.
    public static lengthUnit: string = "µm";

    public static getBluetoothStatusIcon(status: BluetoothStatus): IconDefinition {
        switch (status) {
            case BluetoothStatus.unknown:
                return AppIcons.deviceStatusCheck;
            case BluetoothStatus.disconnected:
                return AppIcons.deviceDisconnected;
            case BluetoothStatus.connected:
                return AppIcons.deviceConnected;
            case BluetoothStatus.unavailable:
                return AppIcons.deviceUnavailable;
            case BluetoothStatus.connecting:
                return AppIcons.deviceConnecting;
            default:
                TypescriptHelper.expectNever(status);
                return AppIcons.deviceUnavailable;
        }
    }

    public static getBluetoothStatusText(status: BluetoothStatus): string {
        switch (status) {
            case BluetoothStatus.unknown:
                return this.translateService?.instant("Bluetooth.statusInitializing");
            case BluetoothStatus.disconnected:
                return this.translateService?.instant("Bluetooth.statusDisconnected");
            case BluetoothStatus.connected:
                return this.translateService?.instant("Bluetooth.statusConnected");
            case BluetoothStatus.unavailable:
                return this.translateService?.instant("Bluetooth.statusUnavailable");
            case BluetoothStatus.connecting:
                return this.translateService?.instant("Bluetooth.statusConnecting");
            default:
                TypescriptHelper.expectNever(status);
                return this.translateService?.instant("Bluetooth.statusInitializing");
        }
    }

    public static format(value: string, digits: number = 1): string {
        const numberValue: number = Number(value);
        if (isNaN(numberValue)) {
            return value;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return numberValue.toFixed(digits);
        }
    }

    /**
     * Converts a given value in millimeters (mm) or mils (thousandths of an inch) to micrometers (µm).
     * @param value - The value to convert.
     * @param unit - The unit of the value ("mm" or "mils").
     * @returns The converted value in micrometers (µm).
     */
    public static convertToMicrometers(value: number, unit: string|"mm"|"mils"|"mil"|"inch"|"pc"|"um"|"µm"): number {
        // 1 mm = 1000 µm
        // 1 mil = 25.4 µm
        if (unit === "mm") {
            return value * 1000.0;
        } else if (unit === "mil" || unit === "mils") {
            return value * 25.4;
        } else if (unit === "inch") {
            return value * 25400;
        } else if (unit === "pc") {
            return value * (3.085677581 * 10e22);
        } else {
            return value;
        }
    }

    /**
     * Converts a given value in micrometers (µm) to millimeters (mm) or mils (thousandths of an inch).
     * @param value - The value in micrometers (µm) to convert.
     * @param targetUnit - The unit to convert to ("mm" or "mils").
     * @returns The converted value in the target unit.
     */
    public static convertFromMicrometers(value: number, targetUnit: string|"mm"|"mils"|"mil"|"inch"|"pc"|"um"|"µm"): number {
        if (targetUnit === "mm") {
            return value / 1000.0;
        } else if (targetUnit === "mil" || targetUnit === "mils") {
            return value / 25.4;
        } else if (targetUnit === "inch") {
            return value / 25400;
        } else if (targetUnit === "pc") {
            return value / (3.085677581 * 10e22);
        } else {
            return value;
        }
    }

    public static getPrecisionForUnit(unit?: string): number {
        switch (unit) {
            case "Unit.mm":
            case "mm":
                return 4;
            case "Unit.mil":
            case "Unit.mils":
            case "mil":
            case "mils":
                return 3;
            case "Unit.inch":
            case "inch":
                return 6;
            case "Unit.pc":
            case "pc":
                return 26;
            case "Unit.µm":
            case "Unit.um":
            case "µm":
            case "um":
                return this.lengthUnit != "µm" ? this.getPrecisionForUnit(this.lengthUnit) : 1;
        }

        return 1;
    }

    public static formatValue(measuredValue: MeasuredValue): string {
        if (!measuredValue.value) {
            return "";
        }
        // Special operation if we have to convert length units
        if ((measuredValue.unit == "Unit.µm" || measuredValue.unit == "µm") && this.lengthUnit != "µm") {
            const value: number = parseFloat(measuredValue.value);
            if (isNaN(value)) { return this.format(measuredValue.value); }

            if (["mm", "mil", "inch", "pc"].includes(this.lengthUnit)) {
                return this.convertFromMicrometers(value, this.lengthUnit).toFixed(this.getPrecisionForUnit(this.lengthUnit));
            }
        }
        return this.format(measuredValue.value);
    }

    public static formatValueUnit(measuredValue: MeasuredValue): string {
        return measuredValue.unit ? this.formatUnit(measuredValue.unit) : "";
    }

    public static formatUnit(unit?: string): string {
        // Special operation if we have to convert length units
        if ((unit == "Unit.µm" || unit == "µm") && this.lengthUnit != "µm") {
            return `Unit.${this.lengthUnit}`;
        }
        return unit ?? "";
    }

    public static getUnitForColumn(columnName: string, measurements: Array<Measurement>, separator: boolean = false, translate: boolean = false): string {
        for (const measurement of measurements) {
            if (measurement?.values) {
                const measuredValue: MeasuredValue|undefined = measurement.values.find((value: MeasuredValue) => value.name == columnName);
                if (measuredValue) {
                    let unit: string = this.formatValueUnit(measuredValue);
                    if (translate && unit.length > 0) {
                        unit = this.translateService?.instant(unit) as string ?? unit;
                    }
                    return separator ? `/${unit}` : unit;
                }
            }
        }
        return "";
    }

    public static getTitleForColumn(columnName: string): string {
        return columnName
            ? (this.translateService?.instant(columnName) as string ?? "").replace("Measurement.", "")
            : "";
    }

    public static txPowerToIcon(txPower: number|undefined): IconDefinition|undefined {
        if (txPower === undefined || txPower === null || txPower >= 127) {
            return undefined;
        }

        if (txPower <= -80) {
            return AppIcons.signalStrength1; // Weak
        } else if (txPower <= -60) {
            return AppIcons.signalStrength2; // Fair
        } else if (txPower <= -40) {
            return AppIcons.signalStrength3; // Good
        } else {
            return AppIcons.signalStrength4;  // Strong
        }
    }

}
