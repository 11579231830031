<ng-container [ngSwitch]="bluetoothStatus">
    <div *ngSwitchCase="bluetoothStatusEnum.disconnected">
        <p>{{'DeviceConnector.description' | translate}}</p>
    </div>

    <mat-action-list>
        <button (click)="disconnect(connection)" mat-list-item *ngFor="let connection of connections">
            <fa-icon [icon]="uiHelper.txPowerToIcon(connection.device.signalStrength) ?? icons.signalStrength4" matListItemIcon></fa-icon>
            <span matListItemTitle>{{connection.device.deviceName}}</span>
            <span matListItemLine *ngIf="connection.device.serialNumber">{{connection.device.serialNumber}}</span>
        </button>
        <mat-divider *ngIf="connections.length > 0"></mat-divider>
        <button (click)="scanDevices()" mat-list-item>
            <fa-icon [icon]="icons.bluetoothDeviceIcon" matListItemIcon></fa-icon>
            <span matListItemTitle>{{'DeviceConnector.connect' | translate}}</span>
        </button>
        <button (click)="openBleStatusPage()" mat-list-item>
            <fa-icon [icon]="icons.settingsIcon" matListItemIcon></fa-icon>
            <span matListItemTitle>{{'BleStatus.title' | translate}}</span>
        </button>
    </mat-action-list>
</ng-container>

