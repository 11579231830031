import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as Echarts from "echarts";
import { EChartsOption, EChartsType } from "echarts";

import { BaseComponent } from "../../../base/components/base/base-component";
import { AsyncHelper } from "../../../base/helper/async-helper";
import { Chart } from "../../datamodel/chart";
import { ChartPoint } from "../../datamodel/chart-point";
import { ChartTypes } from "../../datamodel/chart-types";

/**
 * Component to show a chart.
 */
@Component({
    selector: "app-graph",
    templateUrl: "./graph.component.html",
    styleUrls: ["./graph.component.scss"]
})
/* eslint-disable @typescript-eslint/no-magic-numbers */
export class GraphComponent extends BaseComponent {
    private readonly thinSpace: string = " ";

    private readonly colors: Array<string> = [
        "#003f5c",
        "#a05195",
        "#ff7c43",
        "#ffa600",
        "#13b1bc",
        "#8c1b59"
    ];

    @Input()
    public charts: Array<Chart> = [];

    @Input()
    public allowSave: boolean = false;

    @ViewChild("chart")
    private chartElement?: ElementRef;

    protected saving: boolean = false;

    protected isGonio: boolean = false;


    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly modalController: ModalController
    ) {
        super();
    }

    protected override componentInit(): void {
        this.populateChartOptions();
    }
    protected override componentDestroy(): void {
        // Nothing
    }

    private populateChartOptions(): void {
        if (this.charts.length <= 0) {
            return;
        }

        this.isGonio = this.charts.some((chart: Chart) => chart.type == ChartTypes.gonio);

        // Collect and sort all x-axis values
        const allXUnits: Set<string|undefined> = new Set<string|undefined>();
        const allYUnits: Set<string|undefined> = new Set<string|undefined>();
        let showXAxis: boolean = true;
        let showYAxis: boolean = true;
        this.charts.forEach((chart: Chart) => {
            allXUnits.add(chart.unitX);
            allYUnits.add(chart.unitY);
            showXAxis = showXAxis && chart.showXAxis;
            showYAxis = showYAxis && chart.showYAxis;
        });

        const unitX: string = allXUnits.size == 1 && allXUnits.values().next().value !== undefined ? `${this.thinSpace}${allXUnits.values().next().value}` : "";
        const unitY: string = allYUnits.size == 1 && allYUnits.values().next().value !== undefined ? `${this.thinSpace}${allYUnits.values().next().value}` : "";
        this.chartOption.xAxis = {
            type: "value",
            splitNumber: 10,
            axisLabel: {
                rotate: 45,
                formatter: (value: string|any|undefined) => showXAxis ? `${Number(value).toFixed(2)}${(unitX)}` : ""
            }
        };

        let minX: number = Number.MAX_VALUE;
        let maxX: number = Number.MIN_VALUE;
        let minY: number = Number.MAX_VALUE;
        let maxY: number = Number.MIN_VALUE;

        // Populate series data
        this.chartOption.series = this.charts.map((chart: Chart, index: number) => {
            let localMinX: number = Number.MAX_VALUE;
            let localMaxX: number = Number.MIN_VALUE;
            let localMinY: number = Number.MAX_VALUE;
            let localMaxY: number = Number.MIN_VALUE;

            const data: Array<[number, number]> = chart.data.map((point: ChartPoint) => {
                localMinX = Math.min(localMinX, point.x);
                localMaxX = Math.max(localMaxX, point.x);
                localMinY = Math.min(localMinY, point.y);
                localMaxY = Math.max(localMaxY, point.y);
                return [point.x, point.y];
            });

            // Update global min and max values
            minX = Math.min(minX, localMinX);
            maxX = Math.max(maxX, localMaxX);
            minY = Math.min(minY, localMinY);
            maxY = Math.max(maxY, localMaxY);

            const markLines: Array<any> = [];
            if (chart.showMin) { markLines.push({ type: "min", name: "Min", label: { show: true, formatter: `Min: {c}${unitY}` } }); }
            if (chart.showMax) { markLines.push({ type: "max", name: "Max", label: { show: true, formatter: `Max: {c}${unitY}` } }); }
            if (chart.showAverage) { markLines.push({ type: "average", name: "Average", label: { show: true, formatter: `Avg: {c}${unitY}` } }); }
            if (chart.centerX) {
                markLines.push({
                    xAxis: chart.centerX,
                    lineStyle: {type: "dashed", color: "#000080" },
                    label: { show: false }
                });
            }

            return ({
                name: chart.title,
                type: "line",
                smooth: true,
                symbol: "none",
                lineStyle: {
                    width: 3,
                    shadowColor: "rgba(0,0,0,0.3)",
                    shadowBlur: 10,
                    shadowOffsetY: 8,
                    color: this.colors[index % this.colors.length]
                },
                data: data,
                markLine: {
                    silent: true,
                    symbol: ["none", "none"],
                    data: markLines
                },
                itemStyle: {
                    color: "rgba(0, 150, 255, 0.3)"
                }

            });
        });

        this.chartOption.xAxis.min = minX;
        this.chartOption.xAxis.max = maxX;



        // Add Haze and Gloss areas if isGonio is true
        if (true || this.isGonio) {
            this.chartOption.series.push({
                type: "line",
                markArea: { silent: true, data: [[{ name: "Gloss", xAxis: 19.1, yAxis: minY - ((maxY - minY) * 0.1) }, { xAxis: 20.9, yAxis: maxY + ((maxY - minY) * 0.1) }]] },
                itemStyle: { color: "#A0D0FF" }
            });
            this.chartOption.series.push({
                type: "line",
                markArea: { silent: true, data: [[{ name: "Haze", xAxis: 17.2, yAxis: minY - ((maxY - minY) * 0.1) }, { xAxis: 19.0, yAxis: maxY + ((maxY - minY) * 0.1) }]] },
                itemStyle: { color: "#A0FFD0" }
            });
            this.chartOption.series.push({
                type: "line",
                markArea: { silent: true, data: [[{ name: "Haze", xAxis: 21.0, yAxis: minY - ((maxY - minY) * 0.1) }, { xAxis: 22.8, yAxis: maxY + ((maxY - minY) * 0.1) }]] },
                itemStyle: { color: "#A0FFD0" }
            });
            this.chartOption.series.push({
                type: "line",
                markArea: { silent: true, data: [[{ name: "Comp.", xAxis: 15.4, yAxis: minY - ((maxY - minY) * 0.1) }, { xAxis: 17.2, yAxis: maxY + ((maxY - minY) * 0.1) }]] },
                itemStyle: { color: "#e3fff1" }
            });
            this.chartOption.series.push({
                type: "line",
                markArea: { silent: true, data: [[{ name: "Comp.", xAxis: 22.9, yAxis: minY - ((maxY - minY) * 0.1) }, { xAxis: 24.7, yAxis: maxY + ((maxY - minY) * 0.1) }]] },
                itemStyle: { color: "#e3fff1" }
            });
        }

        // Add buffer to Y-axis
        const buffer: number = (maxY - minY) * 0.1;
        this.chartOption.yAxis = {
            type: "value",
            min: minY - buffer,
            max: maxY + buffer,
            axisLabel: {
                formatter: (value: number) => showYAxis ? `${value.toFixed(2)}${unitY}` : ""
            }
        };

        // Add visual map configuration if needed
        this.chartOption.visualMap = [
            {
                show: false,
                type: "continuous",
                seriesIndex: 0,
                min: minY,
                max: maxY
            }
        ];
    }

    public chartOption: EChartsOption = {
        visualMap: [],
        animationDuration: 250,
        xAxis: {
            type: "category",
            data: []
        },
        yAxis: {
            type: "value"
        },
        tooltip: {
            trigger: "none"
        },
        series: [
        ]
    };

    protected close(response?: any): void {
        this.modalController.dismiss(response).then();
    }

    protected async save(): Promise<void> {
        this.saving = true;

        // Force UI to update
        this.changeDetectorRef.detectChanges();
        await AsyncHelper.sleep(100);
        this.changeDetectorRef.detectChanges();
        await AsyncHelper.sleep(100);

        const chartInstance: EChartsType|undefined = Echarts.getInstanceByDom(this.chartElement?.nativeElement);

        const imageData: string|undefined = chartInstance?.getDataURL({type: "png"});
        this.close(imageData);

        await AsyncHelper.sleep(1000);
        this.saving = false;
    }
}
