import { Injectable } from "@angular/core";

import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { JobTemplate } from "../../datamodel/job-template";
import { db } from "../../db";

/**
 * JobService stores and loads jobs templates.
 */
@Injectable({
    providedIn: "root"
})
export class JobTemplateService {

    public async listJobTemplates(): Promise<Array<JobTemplate>> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return db.transaction("r", db.jobTemplates, async () => await db.jobTemplates.orderBy("id").reverse().limit(100).toArray());
    }

    public async save(jobTemplate: JobTemplate, newCorrelationId: boolean = false): Promise<number> {
        if (newCorrelationId) {
            jobTemplate.correlationId = CryptoHelper.getUUID();
        }

        return db.transaction("rw", db.jobTemplates, async () => {
            let jobId: number;
            if (jobTemplate.id) {
                await db.jobTemplates.update(jobTemplate.id, jobTemplate);
                jobId = jobTemplate.id;
            } else {
                jobId = await db.jobTemplates.add(jobTemplate);
            }
            console.info(`Template ${jobTemplate.id} (${jobTemplate.correlationId}) saved.`);
            return jobId;
        });
    }

    public async load(id: number): Promise<JobTemplate|undefined> {
        return db.transaction("r", db.jobTemplates,
            async () => await db.jobTemplates.where({ "id": id }).first());
    }

    public async loadByCorrelationId(correlationId: string): Promise<JobTemplate|undefined> {
        return db.transaction("r", db.jobTemplates,
            async () => await db.jobTemplates.where({ "correlationId": correlationId }).first());
    }

    public async delete(id: number): Promise<void> {
        await db.transaction("rw", db.jobTemplates,
            async () => await db.jobTemplates.where({ "id": id }).delete());
    }
}
