import { BleClient, BleDevice } from "@capacitor-community/bluetooth-le";
import { BleService } from "@capacitor-community/bluetooth-le/dist/esm/definitions";
import { Buffer } from "buffer";
import { DateTime } from "luxon";

import { Measurement } from "../../../../../business/datamodel/measurement";
import { UiHelper } from "../../../../../business/helpers/ui-helper";
import { BackendService } from "../../../../../business/services/backend/backend-service";
import { DialogService } from "../../../../../business/services/dialog/dialog.service";
import { SettingsService } from "../../../../../business/services/settings/settings-service";
import { DeviceSubscriptionStatus } from "../../../../../business/services/subscription/device.subscription.status";
import { BluetoothStatus } from "../../bluetooth-status";
import { BluetoothDevice } from "../bluetooth.device";
import { DeviceCodes } from "../device-codes";
import { DeviceNames } from "../device-names";

/**
 * This class implements creating a connection the device "Coating Thickness Gauge" via bluetooth low energy and handles reading measurements from it.
 */
export class CoatingThicknessGauge extends BluetoothDevice {
    constructor(
        backendService: BackendService,
        settingsService: SettingsService,
        private readonly dialogService: DialogService
    ) {
        super(DeviceNames.thicknessGauge, backendService, settingsService);
    }

    public static readonly measurementService: string = "0000ffe0-0000-1000-8000-00805f9b34fb";
    private measurementCharacteristic: string = "0000ffe1-0000-1000-8000-00805f9b34fb";

    public requiredServices: Array<string> = [];
    public requiredAdvertisingServices: Array<string> = [CoatingThicknessGauge.measurementService];
    public override requiredNamePrefix?: string = undefined;

    public async processConnect(device: BleDevice, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus> {
        this.bleDevice = device;

        await BleClient.connect(this.bleDevice.deviceId, () => {
            this.bleDevice = undefined;
            onDisconnect();
        });

        if (this.localName) {
            this.serialNumber = this.localName;
        } else if (device.name == "Simple Peripheral") {
            this.serialNumber = await this.readSerialNumber() ?? "no-serialnumber";
        } else {
            this.serialNumber = device.name;
        }
        this.serialNumber = `${DeviceCodes.thicknessGauge}${this.serialNumber}`;

        const subscriptionStatus: DeviceSubscriptionStatus = await this.getSubscriptionStatus();
        if (subscriptionStatus != DeviceSubscriptionStatus.active) {
            onDisconnect();
            await this.disconnect();
            this.dialogService.licenseRequiredDialog(subscriptionStatus, this.serialNumber);
            return BluetoothStatus.disconnected;
        }

        await BleClient.startNotifications(this.bleDevice.deviceId, CoatingThicknessGauge.measurementService, this.measurementCharacteristic, (dataView: DataView) => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            let valueString: string = this.clean(`${Buffer.from(dataView.buffer.slice(16, 24))}`.trim());
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            let unit: string = this.clean(`${Buffer.from(dataView.buffer.slice(16 + 12, 16 + 15))}`.trim());
            let validMeasurement: boolean = false;

            let numberValue: number = Number.NaN;
            if (valueString.indexOf("NM") >= 0) {
                validMeasurement = true;
                valueString = "Measurement.noMetal";
            } else if (valueString.indexOf("OL") >= 0) {
                validMeasurement = true;
                valueString = "Measurement.overflow";
            } else {
                numberValue = Number.parseFloat(valueString);
                if (!isNaN(numberValue)) {
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    valueString = UiHelper.convertToMicrometers(numberValue, unit).toString(10).replace(",", ".");
                    validMeasurement = true;
                }
            }
            unit = "Unit.µm";

            if (validMeasurement) {
                const measurement: Measurement = new Measurement();
                measurement.device = this.deviceName;
                measurement.deviceId = this.bleDevice?.deviceId;
                measurement.serialNumber = this.serialNumber;
                measurement.timestamp = DateTime.now().toISO() ?? undefined;
                measurement.values = [{
                    value: valueString,
                    unit: unit,
                    name: "Measurement.thickness"
                }];
                onData(measurement);
            }
        });

        // disable crc check
        {
            const command: Array<any> = [
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                0x2a,
                0x00,
                0x00,
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                0x33,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00
            ];
            const data: ArrayBufferLike = new Uint8Array(command).buffer;
            await BleClient.write(this.bleDevice.deviceId, CoatingThicknessGauge.measurementService, this.measurementCharacteristic, new DataView(data));
        }

        // configure the device to send data
        {
            const command: Array<any> = [
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                0x2a,
                0x00,
                0x00,
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                0x1a,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00,
                0x00
            ];
            const data: ArrayBufferLike = new Uint8Array(command).buffer;
            await BleClient.write(this.bleDevice.deviceId, CoatingThicknessGauge.measurementService, this.measurementCharacteristic, new DataView(data));
        }
        return BluetoothStatus.connected;
    }

    private async readSerialNumber(): Promise<string|undefined> {
        if (this.bleDevice) {
            const deviceInfoServiceUUID: string = "0000180a-0000-1000-8000-00805f9b34fb"; // Device Information Service UUID
            const serialNumberCharacteristicUUID: string = "00002a25-0000-1000-8000-00805f9b34fb"; // Serial Number Characteristic UUID

            try {
                const dataView: DataView = await BleClient.read(
                    this.bleDevice.deviceId,
                    deviceInfoServiceUUID,
                    serialNumberCharacteristicUUID
                );

                const serialNumber: string = new TextDecoder().decode(dataView);

                return serialNumber || undefined;
            } catch (error) {
                console.warn("Error reading serial number:", error);
                return undefined;
            }
        }
        return undefined;
    }


    public async disconnect(): Promise<void> {
        if (this.bleDevice) {
            await BleClient.disconnect(this.bleDevice.deviceId);
        }
        this.bleDevice = undefined;
    }

    public async isConnected(): Promise<boolean> {
        try {
            if (this.bleDevice) {
                const services: Array<BleService> = await BleClient.getServices(this.bleDevice.deviceId);
                return services.length > 0;
            }
        } catch (error: any) {
            console.error(error);
        }
        return false;
    }

    private clean(input: string): string {
        return input.replace(/[^\w\s.]/gi, "");
    }
}
