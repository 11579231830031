import { TranslateService } from "@ngx-translate/core";

import { MeasurementTableComponent } from "../../../components/measurement-table/measurement-table.component";
import { Job } from "../../../datamodel/job";
import { MeasuredPoint } from "../../../datamodel/measured-point";
import { MeasuredValue } from "../../../datamodel/measured-value";
import { Measurement } from "../../../datamodel/measurement";
import { Part } from "../../../datamodel/part";
import { StatisticsCalculator } from "../../../helpers/statistics/statistics-calculator";
import { PdfGenerator } from "../pdf-generator";
import { StatisticsTable } from "./statistics-table";
import { Row } from "./table/row";
import { Table } from "./table/table";

/**
 *
 */
export class MeasurementTable {

    constructor(private translationService: TranslateService) {}

    public buildByJobAndPart(job: Job, part: Part): Array<Table> {
        const tables: Array<Table> = [];
        const deviceNames: Array<string> = [];

        const allMeasurements: Array<Measurement> = [];
        for (const mp of job.measuredPoints) {
            if (mp.partId == part.id) {
                for (const measurement of mp.measurements) {
                    allMeasurements.push(measurement);
                    if (measurement.device && !deviceNames.includes(measurement.device)) {
                        deviceNames.push(measurement.device);
                    }
                }
            }
        }

        for (const deviceName of deviceNames) {
            const columns: Array<string> = [];
            columns.push(MeasurementTableComponent.deviceColumnName);
            columns.push(MeasurementTableComponent.measurementPointColumnName);
            columns.push(...this.calculateColumnsByDeviceAndJobAndPart(deviceName, job, part));
            columns.push(MeasurementTableComponent.commentsColumnName);
            const table: Table = new Table();
            const statistics: StatisticsCalculator = new StatisticsCalculator();
            for (const column of columns) {
                let width: "auto"|"*" = "auto";
                if (column == MeasurementTableComponent.commentsColumnName) {
                    width = "*";
                }
                const unit: string = MeasurementTableComponent.getUnitForColumn(column, allMeasurements, true, true);
                table.columns.push({
                    width: width,
                    title: `${MeasurementTableComponent.getTitleForColumn(column)}\n${unit}`,
                    fillColor: PdfGenerator.colorBlack,
                    color: PdfGenerator.colorWhite
                });
            }
            for (const mp of job.measuredPoints) {
                if (mp.partId == part.id) {
                    for (const measurement of mp.measurements) {
                        if (measurement.device != deviceName) {
                            continue;
                        }
                        const row: Row = new Row();
                        let columnIndex: number = 0;
                        for (const column of columns) {
                            let value: string = MeasurementTableComponent.getValueForColumn(column, measurement);
                            const valueUnit: string = MeasurementTableComponent.getUnitForColumn(column, allMeasurements);
                            if (Measurement.isMeasuredValue(measurement, column)) {
                                statistics.addForMeasurementPoint(column, MeasurementTable.getOrderForMeasuredPoint(part, mp), value, valueUnit);
                            }
                            if (value) {
                                value = `${this.translationService.instant(value)}`;
                            }

                            if (column == MeasurementTableComponent.measurementPointColumnName) {
                                if (part.image) {
                                    value = MeasurementTable.getOrderForMeasuredPoint(part, mp);
                                }
                            }
                            row.cells.push({
                                text: `${value}`,
                                fillColor: columnIndex == 0 ? PdfGenerator.colorLightGrey : PdfGenerator.colorWhite
                            });
                            columnIndex++;
                        }
                        table.rows.push(row);
                    }
                }
            }
            tables.push(table);
            tables.push(new StatisticsTable(statistics, this.translationService).build());
        }

        return tables;
    }

    public buildByMeasurements(measurements: Array<Measurement>): Array<Table> {
        const tables: Array<Table> = [];
        const deviceNames: Array<string> = [];

        for (const measurement of measurements) {
            if (measurement.device && !deviceNames.includes(measurement.device)) {
                deviceNames.push(measurement.device);
            }
        }

        for (const deviceName of deviceNames) {
            const measurementTable: Table = new Table();
            const statistics: StatisticsCalculator = new StatisticsCalculator();
            if (measurements.length > 0) {
                const columnNames: Array<string> = [];
                columnNames.push(MeasurementTableComponent.deviceColumnName);
                // columnNames.push(MeasurementTableComponent.timestampColumnName);
                columnNames.push(...this.calculateColumnsByDeviceAndMeasurements(deviceName, measurements));
                columnNames.push(MeasurementTableComponent.commentsColumnName);
                for (const column of columnNames) {
                    const unit: string = MeasurementTableComponent.getUnitForColumn(column, measurements, true, true).trim();
                    measurementTable.columns.push({
                        title: `${MeasurementTableComponent.getTitleForColumn(column)}\n${unit}`,
                        width: [MeasurementTableComponent.commentsColumnName].includes(column) ? "*" : "auto",
                        fillColor: PdfGenerator.colorBlack,
                        color: PdfGenerator.colorWhite
                    });
                }

                for (const measurement of measurements) {
                    if (measurement.device != deviceName) {
                        continue;
                    }
                    const row: Row = new Row();
                    for (const column of columnNames) {
                        const value: string = MeasurementTableComponent.getValueForColumn(column, measurement).trim();
                        const unit: string = MeasurementTableComponent.getUnitForColumn(column, [measurement]);
                        if (Measurement.isMeasuredValue(measurement, column)) {
                            statistics.add(column, value, unit);
                        }
                        const cellValue: string = value ? `${this.translationService.instant(value)}` : "";
                        row.cells.push(`${cellValue}`);
                    }
                    measurementTable.rows.push(row);
                }
            }

            tables.push(measurementTable);
            tables.push(new StatisticsTable(statistics, this.translationService).build());
        }

        return tables;
    }

    private calculateColumnsByDeviceAndJobAndPart(deviceName: string, job: Job, part: Part): Array<string> {
        const columns: Array<string> = new Array<string>();
        for (const measuredPoint of job.measuredPoints) {
            if (part.id == measuredPoint.partId) {
                for (const measurement of measuredPoint.measurements) {
                    if (measurement.device == deviceName) {
                        for (const measuredValue of measurement.values) {
                            if (measuredValue.name && !columns.includes(measuredValue.name) && MeasuredValue.isValidValue(measuredValue)) {
                                columns.push(measuredValue.name);
                            }
                        }
                    }
                }
            }
        }
        return columns;
    }

    private calculateColumnsByDeviceAndMeasurements(deviceName: string, measurements: Array<Measurement>): Array<string> {
        const columns: Array<string> = new Array<string>();
        for (const measurement of measurements) {
            if (measurement.device && measurement.device == deviceName) {
                for (const measuredValue of measurement.values) {
                    if (measuredValue.name && !columns.includes(measuredValue.name) && MeasuredValue.isValidValue(measuredValue)) {
                        columns.push(measuredValue.name);
                    }
                }
            }
        }
        return columns;
    }

    public static getOrderForMeasuredPoint(part: Part, measuredPoint: MeasuredPoint): string {
        if (part.image?.measurementPoints) {
            for (const measurementPoint of part.image?.measurementPoints) {
                if (measuredPoint.measurementPointId == measurementPoint.id) {
                    return `${measurementPoint.order}`;
                }
            }
        }
        return "---";
    }
}
